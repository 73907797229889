



































































import { validZipcode } from '@/concerns/validator';

// Components
import SearchList from '@/components/GlobalSearch/SearchList.vue';
import VehicleDropdown from './VehicleDropdown.vue';
import { BTooltip } from 'bootstrap-vue';
import ZeroCoverage from '@/components/Onramp/ZeroCoverage.vue';

//Types
import { Service } from '@/types/Services';
import { ServiceSelectionCategory } from '@/types/resources/ServiceSelectionCategories';
import { ServiceSelectionInterview } from '@/types/resources/ServiceSelectionInterview';
import { isNullish } from '@/utilities';
import segmentServices from '@/services/segmentServices';
import zipcodeService from '@/services/zipcodeService';
import { Subscription } from '@/types/microservices/Subscriptions';
import { ZipcodeLocation } from '@/types/ZipcodeLocation';
import CombinedComponent, { userMixin } from '@/mixins';
import { AppointmentService } from '@/types/AppointmentService';

export default CombinedComponent(userMixin).extend({
  name: 'GlobalSearch',

  components: {
    SearchList,
    BTooltip,
    VehicleDropdown,
    ZeroCoverage
  },

  props: {
    buttonClasses: {
      type: String,
      required: false,
      default: 'btn-primary'
    }
  },

  data() {
    return {
      loading: false,
      loadingTargetMarket: false,
      zipcode: this.$store.getters['user/getZipcode'] || '',
      disableTooltip: true,
      serviceForOnRamp: {} as any,
      routeForOnramp: '',
      defaultDataHover: false,
      outOfMarketZipcode: '',
      currentLocation: null as ZipcodeLocation | null
    };
  },

  computed: {
    homepage(): boolean {
      return ['home', 'openbayplus'].includes(this.$route.name ?? '');
    },

    services(): Service[] {
      return this.$store.getters['services/getServices'];
    },

    appointmentServices(): AppointmentService[] {
      return this.$store.getters['services/getAppointmentServices'];
    },

    searchData(): any[] {
      return [...this.services, ...this.appointmentServices];
    },

    defaultData(): ServiceSelectionInterview[] {
      return this.popularServices.interviews;
    },

    fuseSearchKeys(): any[] {
      const serviceKeys = [
        {
          name: 'name',
          weight: 0.7
        },
        {
          name: 'category_name',
          weight: 0.3
        }
      ];
      return serviceKeys;
    },

    defaultDataLabel(): string {
      return 'Popular Services';
    },

    placeholder(): string {
      if (document.documentElement.clientWidth <= 768) {
        return 'Search services';
      } else {
        return 'Search services or describe problem';
      }
    },

    popularServices(): ServiceSelectionCategory {
      return this.$store.getters['services/getPopularServiceInterviews'] || {};
    },

    sortedServices(): ServiceSelectionInterview[] {
      let toSort = [...this.popularServices.interviews];
      return toSort.sort((a, b) => a.weight - b.weight);
    },

    validZipcode(): boolean {
      const validZip = this.zipcode.match(/[0-9]{5}/g);
      return this.zipcode !== '' && validZip !== null;
    },

    globalUserZipcode(): string {
      return this.$store.getters['user/getZipcode'] || '';
    },

    openbayPlusSubscription(): Subscription | undefined {
      return this.$store.getters['user/getSubscriptions'][0];
    }
  },

  watch: {
    globalUserZipcode(newVal: string, oldVal: string): void {
      if (newVal !== oldVal) this.zipcode = newVal;
    },

    popularServices: {
      immediate: true,
      handler: function () {
        if (isNullish(this.popularServices)) {
          this.loading = true;
        } else {
          this.loading = false;
        }
      }
    }
  },

  created(): void {
    if (this.services.length <= 1) {
      this.loading = true;
      this.$store.dispatch('services/fetchServices').then(() => {
        this.loading = false;
      });
    }
  },

  methods: {
    setServiceForOnramp(service: Service | AppointmentService): void {
      if (!service.type) {
        const serviceId = service.id.toString();
        segmentServices.trackGeneral('Service Search Submitted', {
          service_id: serviceId
        });
      }
      this.routeForOnramp = 'service';
      this.serviceForOnRamp = service;
    },

    goToAddVehicle(): void {
      this.$store.commit('onrampCart/clearOwnedVehicle');
      this.$store.commit('onrampCart/clearVehicleToCreate');
      this.$store.commit('user/setZipcode', this.zipcode);
      this.$store.commit('onrampCart/setZipcode', this.zipcode);
      this.$router.push({ name: 'add-vehicle-onramp' });
    },

    selectDefaultOption(selectedOption: ServiceSelectionInterview): void {
      segmentServices.trackGeneral('Service Added', {
        service_selection_method: 'Popular Services'
      });
      this.routeForOnramp = 'interview';
      this.serviceForOnRamp = {
        interview: JSON.stringify(selectedOption)
      };
    },

    clearSelectedService(): void {
      this.serviceForOnRamp = {};
      this.routeForOnramp = '';
    },

    goToAllServices(): void {
      this.$store.commit('onrampCart/reset');
      if (this.zipcode?.length) {
        if (!validZipcode(this.zipcode)) return this.toggleTooltip();
        this.checkZipcodeCoverage().then(() => {
          this.$router.push({
            name: 'service',
            params: { zipcode: this.zipcode }
          });
        });
      } else {
        this.$router.push({ name: 'service' });
      }
    },

    updateUserLocation(zipcode: string): Promise<void> {
      return this.$store.dispatch('user/fetchLocation', zipcode).then((location: ZipcodeLocation) => {
        this.currentLocation = location;
        this.$store.commit('onrampCart/setLocation', location);
      });
    },

    verifyZipcodeIsInMarket(zipcode: string): Promise<void> {
      if (!zipcode) return Promise.reject(new Error('Zipcode cannot be blank'));
      return zipcodeService.isInTargetMarket(zipcode).then((inMarket) => {
        if (!inMarket) throw new Error('Zipcode is OOTM');
      });
    },

    checkZipcodeCoverage(): Promise<void> {
      this.loadingTargetMarket = true;
      this.outOfMarketZipcode = '';

      return this.verifyZipcodeIsInMarket(this.zipcode)
        .then(() => this.updateUserLocation(this.zipcode))
        .catch((error) => {
          this.outOfMarketZipcode = this.zipcode;
          this.zipcode = '';
          throw error;
        })
        .finally(() => (this.loadingTargetMarket = false));
    },

    startOnramp(): void {
      if (!validZipcode(this.zipcode)) return this.toggleTooltip();
      this.checkZipcodeCoverage()
        .then(() => {
          this.$store.commit('onrampCart/reset');
          if (this.routeForOnramp !== '') {
            let location = this.currentLocation;
            // user selected an appointment service
            if ('type' in this.serviceForOnRamp && location) {
              const type = this.serviceForOnRamp['type'] as string;
              this.$router.push({
                name: 'auto-repair-service-locations',
                params: { stateAbbreviation: location.state!.toString(), city: location.city!.toString() },
                query: { type: type }
              });
            } else {
              let params = {
                ...this.serviceForOnRamp,
                zipcode: this.zipcode
              };
              this.$router.push({
                name: this.routeForOnramp,
                params
              });
            }
          } else {
            this.$router.push({
              name: 'service',
              params: { zipcode: this.zipcode }
            });
          }
        })
        .catch(() => {
          // If the zipcode isn't valid, it will bubble up here.
          // The logic for displaying the ZeroCoverage modal is handled by the modal component,
          // so this catch block can be empty.
        });
    },

    toggleTooltip(): void {
      if (!this.validZipcode && !isNullish(this.$refs.tooltip)) {
        this.disableTooltip = false;
        (this.$refs.tooltip as any).$emit('enable');
        (this.$refs.tooltip as any).$emit('open');
      } else if (!isNullish(this.$refs.tooltip)) {
        this.disableTooltip = true;
        (this.$refs.tooltip as any).$emit('disable');
        (this.$refs.tooltip as any).$emit('close');
      }
    },

    checkGeoLocationPermission(): void {
      if (navigator.permissions) {
        navigator.permissions.query({ name: 'geolocation' }).then((res) => {
          if (res.state === 'granted') {
            segmentServices.trackGeneral('Responded to Location Request', {
              locationPermissionState: 'granted'
            });
          }

          // Asking for location regardless of permissions, as it triggers permissions request
          // if the current status is 'pending'
          this.askForLocation();
        });
      }
    },

    askForLocation(): void {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            var lat = position.coords.latitude;
            var long = position.coords.longitude;
            var point = new google.maps.LatLng(lat, long);
            new google.maps.Geocoder().geocode({ location: point }, (res, _status) => {
              const zipcode = res[0].formatted_address.match(/[0-9]{5}/g);
              if (zipcode) {
                if (this.zipcode === '') {
                  this.zipcode = zipcode[0];
                  this.$store.dispatch('user/fetchLocation', this.zipcode);
                }
              } else {
                this.zipcode = this.globalUserZipcode;
              }

              this.toggleTooltip();
            });
          },
          (_error) => {
            segmentServices.trackGeneral('Responded to Location Request', {
              locationPermissionState: 'denied'
            });
            this.toggleTooltip();
          },
          {
            timeout: 5000
          }
        );
      }
    }
  }
});
