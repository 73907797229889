import { httpClient } from '@/api';
import { handleError, handleSuccess } from '@/services/helpers';
import { AppointmentServicesResource } from '@/types/AppointmentService';

export default {
  fetchAppointmentServices() {
    return httpClient
      .get<AppointmentServicesResource>('/v3/appointment_services')
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  }
};
